import { autobind } from "core-decorators";
import { Singleton } from "typescript-ioc";
import * as FollowUsItemClick from "@ori-events/follow-us-item-click";

@Singleton
export class FollowUsAnalytics {
    public get key(): string {
        return "followUsAnalytics";
    }

    private readonly SOCIAL_SHARE_CONTAINER_SELECTOR: string = ".js-follow-us-analytics";

    public constructor() {
        const socialShareIcons = Array.from(
            document.querySelectorAll(this.SOCIAL_SHARE_CONTAINER_SELECTOR)
        );

        socialShareIcons.forEach((icon) => {
            icon.addEventListener("click", this.sendInfoToAnalyticsLayer);
        });
    }

    @autobind
    private sendInfoToAnalyticsLayer(event: MouseEvent): void {
        const elementClicked = (event.target as HTMLElement).title || "";
        const customEvent = new CustomEvent<FollowUsItemClick.Data>(FollowUsItemClick.Name, {
            detail: {
                target: elementClicked,
            },
        });

        window.dispatchEvent(customEvent);
    }
}
